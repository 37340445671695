.slider-heading-middle {
    padding-top: 30px;
    h2 {
      margin-left: 70px;
      color: #fff;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0;
      font-size: 26px;
    }
    img {
      margin: 0 10px;
    }
  }

  .hide-text{
    opacity: 0;
    -webkit-transition: opacity 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;
  }

  .show-text{
    opacity: 1;
    -webkit-transition: opacity 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;
  }

  @media only screen and (max-width: 576px) {
  
    .slider-heading-middle h2{
      font-size: 16px;
      margin-left: 50px;
    }
  
  }