.content-schedule-section{
    height: 58px;
    background: linear-gradient(180deg, #ec5a11 0%, #d12117 32.63%, #a80e2d 57.75%, #ec5a11 100%);
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;


    h5{
        margin: 0;
        font-weight: 400;
        text-transform: uppercase;
        span {
            font-weight: 600;
        }
    }

}