.slider-section-middle {
  padding: 0 115px;
  // height: 547px;
  padding-bottom: 110px;
}
.slick-vertical .slick-slider {
  margin-top: -20px;
  z-index: 1;
}

.slick-horizontal .slick-slider {
  margin-top: -10px;
  z-index: 1;
}

.slick-vertical .slick-list {
  margin: 0 -35px !important;
}

.slick-vertical .slick-slide {
  padding: 0 40px;
}

.slick-horizontal .slick-list {
  margin: 0 -5px !important;
}

.slick-horizontal .slick-slide {
  padding: 0 10px;
}

.slick-vertical .slick-next {
  right: -20px;
  top: 50%;
  height: auto;
}
.slick-horizontal .slick-next {
  right: -20px;
  top: 50%;
  height: auto;
}

.slick-vertical .slick-prev {
  left: -33px;
  right: auto;
  top: 50%;
  z-index: 10;
  height: auto;
}

.slick-horizontal .slick-prev {
  left: -30px;
  right: auto;
  top: 50%;
  z-index: 10;
  height: auto;
}

.slick-prev:before,
.slick-next:before {
  font-size: 35px;
}

.slick-vertical .slick-prev:before,.slick-horizontal .slick-prev:before {
  display: block;
  height: 46px;
  width: 31px;
  background: url("../../../../../public/icons/new-white-left.png") no-repeat;
  content: "";
}

.slick-vertical .slick-next:before, .slick-horizontal .slick-next:before {
  display: block;
  height: 46px;
  width: 31px;
  background: url("../../../../../public/icons/new-white-right.png") no-repeat;
  content: "";
}

.slider-heading {
  h2 {
    margin-left: 70px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 26px;
    text-shadow: rgb(255, 255, 255) 3px 0px 0px,
      rgb(255, 255, 255) 2.83487px 0.981584px 0px,
      rgb(255, 255, 255) 2.35766px 1.85511px 0px,
      rgb(255, 255, 255) 1.62091px 2.52441px 0px,
      rgb(255, 255, 255) 0.705713px 2.91581px 0px,
      rgb(255, 255, 255) -0.287171px 2.98622px 0px,
      rgb(255, 255, 255) -1.24844px 2.72789px 0px,
      rgb(255, 255, 255) -2.07227px 2.16926px 0px,
      rgb(255, 255, 255) -2.66798px 1.37182px 0px,
      rgb(255, 255, 255) -2.96998px 0.42336px 0px,
      rgb(255, 255, 255) -2.94502px -0.571704px 0px,
      rgb(255, 255, 255) -2.59586px -1.50383px 0px,
      rgb(255, 255, 255) -1.96093px -2.27041px 0px,
      rgb(255, 255, 255) -1.11013px -2.78704px 0px,
      rgb(255, 255, 255) -0.137119px -2.99686px 0px,
      rgb(255, 255, 255) 0.850987px -2.87677px 0px,
      rgb(255, 255, 255) 1.74541px -2.43999px 0px,
      rgb(255, 255, 255) 2.44769px -1.73459px 0px,
      rgb(255, 255, 255) 2.88051px -0.838247px 0px;
  }
  img {
    margin: 0 10px;
  }
}

.explore-new {
  color: #fff;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  display: block;
}

@media only screen and (max-width: 787px) {
  .slider-section-middle {
    padding: 0 45px 100px 45px;
  }

  .slick-vertical .slick-slide {
    padding: 0 20px;
  }

  .slick-vertical .slick-list {
    margin: 0 -15px !important;
  }

  .slick-horizontal .slick-list {
    margin: 0 !important;
  }

  .slick-horizontal .slick-slide {
    padding: 0;
  }
}

@media only screen and (max-width: 576px) {
  .slider-section-middle {
    padding: 0 15px 100px 15px;
  }

  .slick-horizontal .slick-list {
    margin: 0 !important;
  }

  .slick-horizontal .slick-slide {
    padding: 0 15px;
}

  .slick-vertical .slick-slider {
    margin-top: -5px;
  }

  .slick-horizontal .slick-slider {
    margin-top: 5px;
  }
  .slick-horizontal .slick-next{
    width: 31px;
    right: -10px;
  }

  .slick-horizontal .slick-prev{
     width: 31px;
    left: -10px;
  }

  .explore-new{
    display: none;
  }

  .slick-vertical .slick-slide{
    padding: 0 10px;
  }


  .slick-vertical .slick-list{
    margin: 0 10px !important;
  }

  .slick-vertical .slick-prev{
    width: 31px;
    left: -10px;
  }

  .slick-vertical .slick-next{
    width: 31px;
    right: -10px;

  }
}
