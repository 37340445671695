.slider-section-top-drama{
    padding: 0 150px;
    // min-height: 828px;
    padding-bottom: 100px;
}

.video-player-section-full{
    width: 85%;
    margin: 0 auto;
}

.video-player-section {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    border: 3px solid #fca703;
    border-radius: 30px;
    background: #000;
}

.video-player-section iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 26px;
}

@media only screen and (max-width: 787px) {
    .slider-section-top-drama{
        padding: 0 45px 60px 45px;
        // min-height: 545px;
    }

    .video-player-section-full{
        width: 100%;
    }
}

@media only screen and (max-width: 576px) {
    .slider-section-top-drama{
        padding: 0 30px 60px 30px;
        // min-height: 545px;
    }

    .video-player-section-full{
        width: 100%;
    }
}