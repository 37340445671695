.text-page-section {
    padding: 0 115px;
    padding-bottom: 60px;
    padding-top: 50px;

    p{
        text-align: justify;
    }
}

@media only screen and (max-width: 576px) {
    .text-page-section {
        padding-left: 45px;
        padding-right: 45px;
        margin: 0;
    }
  
  }