.video-section-nav {
    display: flex;
    justify-content: end;
    margin-right: 25px;
    margin-top: 30px;
}

.video-nav-btn-left-lock {
    margin-right: 15px;
}

.video-nav-btn-right {
    cursor: pointer;
}

.video-nav-btn-left{
    cursor: pointer;
    margin-right: 15px;
}