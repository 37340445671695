.vertical-slide-top{
    img {
        transform: scale(.9);
        -webkit-transition: transform 0.5s ease-in-out;
        -moz-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        
    }
    &:hover img{
        transform: scale(1);
        -webkit-transition: transform 0.5s ease-in-out;
        -moz-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
      }
  }