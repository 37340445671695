.video-section-content{
    padding: 20px 115px 80px 115px;
    // min-height: 670px;
}

.video-section-row {
    margin: 0 -22px;
    min-height: 289px;
}

.video-section-content .nav{
	justify-content: center;
}
.video-section-content .nav-tabs{
	border-bottom: 0;
}

@media only screen and (max-width: 787px) {
    .video-section-content{
        padding: 20px 45px 80px 45px;
    }
}
