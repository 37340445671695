.program-section-column{
    padding: 0px 10px 20px;
}
.program-image-single{
    width: 100%;
    .program-image-wraper {
        width: 100%;
        overflow: hidden;
        border: 2px solid #fff;
        border-radius: 10px;
        img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        }



    }
}


