.search-container {
    background-color: #00000099;
    height: 74px;
    display: flex;
    align-items: center;
}

.search-modal .search-header {
    display: flex;
    align-items: center;
}

.search-modal .search-header .search-input {
    position: relative;
    margin-left: 10px;
}


.search-modal .search-header .search-input .search-icon {
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 9;
    font-size: 22px;
}


.search-modal .search-header .search-input input {
    width: 100%;
    border-radius: 4px;
    background-color: hsla(0, 0%, 80%, 0.949);
    font-size: 16px;
    color: #fff;
    padding: 10px 40px;
    height: 44px;
}

.search-modal .search-header .search-input .close-icon {
    position: absolute;
    right: 15px;
    top: 0;
    font-size: 34px;
    line-height: 34px;
    color: #4d4d4d;
    cursor: pointer;
    border: 0;
    background: transparent;
}

.search-modal .search-header img {
    width: 60px;
    margin-left: 2px;
}


.result-single {
    background: linear-gradient(180deg, #ec5a11 0%, #d12117 32.63%, #a80e2d 57.75%, #ec5a11 100%);
    height: 42px;
    border: 1px solid #000;
    margin-top: 0px;
    padding: 5px;
    display: flex;
    align-items: center;

    a{
        text-decoration: none;
    }

    h3 {
        margin: 0;
        text-transform: uppercase;
    }

}
