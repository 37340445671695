.Modal {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(56, 56, 56, 0.902);
    z-index: 10;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: #f00;
    z-index: 60;
  }

  button.search-icon {
    background: transparent;
    border: 0;
    color: #7e7e7e;
}

button.search-icon:focus-visible {
  outline: 0;
}

.search-box{
  background-color: #dee0e3;
  border: 2px solid #c8c8c8;
  border-radius: 30px;
  width: 200px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  cursor: pointer;
  .search-input{
      background: transparent;
      border: 0;
      width: 135px;
      color: #7e7e7e;
      margin: 2px;
  }

  .search-icon {
      background: transparent;
      border: 0;
      color: #7e7e7e;
  }
}