.schedule-home-section {
  display: flex;
}

.schedule-single-box {
  // width: 12.5%;
  border-top: 2px solid #000;
  border-bottom: 1px solid #000;
}

.schedule-home-boxes {
  // display: flex;
  // width: 100%;
  width: calc(100% - 160px);
}

.schedule-single-box-detail {
  // flex: 1;
}

.double-box {
  height: 86px;
  background: linear-gradient(
    -90deg,
    #b40f33 10.23%,
    #da2220 54.55%,
    #ed5d0f 86.58%,
    #fcb008 100%
  );
  display: flex;
  align-items: center;
  min-width: 160px;
  max-width: 160px;
  width: 160px;
}

.double-box h5 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.schedule-name {
  background: linear-gradient(
    -90deg,
    #b40f33 10.23%,
    #da2220 54.55%,
    #ed5d0f 86.58%,
    #fcb008 100%
  );
  height: 42px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
  }
}
.schedule-datetime {
  background: linear-gradient(
    180deg,
    #ec5a11 0%,
    #d12117 32.63%,
    #a80e2d 57.75%,
    #ec5a11 100%
  );
  height: 42px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.schedule-name h6 {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  margin: 0 5px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.schedule-datetime p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}

.time-bold {
  font-size: 14px;
  font-weight: 700;
}

.schedule-nav-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #fff 0%, #cbcfd7 100%);
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

.canPressedBtn {
  cursor: pointer;
}

.schedule-home-boxes .slick-slider{
  display: block;
}
.schedule-home-boxes .slick-prev, .schedule-home-boxes .slick-next{
  display: block;
  left: auto;
  right: auto;
  position: relative;
  transform: none;
  background: #f00;
  height: auto;
  width: 34px;
  background: linear-gradient(0deg, #fff 0%, #cbcfd7 100%);
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

.schedule-home-boxes .slick-next:before{
  background: url("../../../public/icons/blog-icon-right-copy.png") no-repeat;
  width: 34px;
  height: 52px;
}

.schedule-home-boxes .slick-prev:before{
  background: url("../../../public/icons/blog-icon-left-copy.png") no-repeat;
  width: 34px;
  height: 52px;
}

.schedule-home-boxes .slick-prev:hover, .schedule-home-boxes .slick-prev:focus, .schedule-home-boxes .slick-next:hover, .schedule-home-boxes .slick-next:focus{
  background: linear-gradient(0deg, #fff 0%, #cbcfd7 100%);
}

@media only screen and (max-width: 1200px) {
  .schedule-home-boxes .slick-slider{
    display: flex;
  }
}

@media only screen and (max-width: 576px) {

  .schedule-home-boxes .slick-slider{
    display: flex;
  }

  .schedule-home-boxes{
    width: calc(100% - 110px);
  }
  .double-box {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    h5 {
      font-size: 14px;
    }
  }
  .schedule-name h6 {
    font-size: 16px;
  }

  .schedule-datetime p{
    font-size: 12px;
  }
  .time-bold{
    font-size: 13px;
  }
}
