.schedule-page-section .nav-tabs {
    border: 0;

    .nav-item{
        flex: 1;
    }
    .nav-link{
        width: 100%;
        border: 0;
        border-right: 1px solid #000;
        border-left: 1px solid #000;
        border-radius: 0;
        font-size: 24px;
        font-weight: 700;
        background: linear-gradient(180deg, #ec5a11 0%, #d12117 32.63%, #a80e2d 57.75%, #ec5a11 100%);
        color: #fff;
        padding: 0.5rem;
    }
    
    .nav-link.active{
        color: #000;
        background: linear-gradient(180deg, #b3c1dc 0%, #d8dce7 32.63%, #e5e5ed 57.75%, #b3c1dc 100%);
    }

}

.schedule-tab-day{
    margin-top: 50px;
    padding: 0 150px;
    min-height: 544px;
    padding-bottom: 30px;
    .slick-vertical-top .slick-prev, .slick-vertical-top .slick-next{
        top: calc(40% - 22px);
    }
    .slick-vertical-top .slick-prev{
        left: 15px;
    }
}




@media only screen and (max-width: 992px) {
    .schedule-page-section .nav-tabs .nav-link{
        font-size: 14px;
    }

    .schedule-tab-day{
        padding-left: 30px;
        padding-right: 30px;
        min-height: 435px;
    }
}


@media only screen and (max-width: 787px) {
    .schedule-page-section .nav-tabs .nav-link{
        font-size: 12px;
    }

    .schedule-tab-day{
        padding-left: 30px;
        padding-right: 30px;
        min-height: 435px;
    }
}


@media only screen and (max-width: 576px) {

    .schedule-tab-day{
        min-height: 330px;
        margin-top: 30px;
    }
}