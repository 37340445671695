.listItem {
    width: 100%;
    background-color: var(--main-color);
    overflow: hidden;
    cursor: pointer;
    color: white;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      
      border: 2px solid #fff;
    }
    &:hover img{
      border: 4px solid #fca703;
    }
}

.vertical-slide{
    img {
        transform: scale(.9);
        -webkit-transition: transform 0.5s ease-in-out;
        -moz-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
    }
    &:hover img{
        transform: scale(1);
        -webkit-transition: transform 0.5s ease-in-out;
        -moz-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
      }
}

.horizontal-slide{
    img {
        transform: scale(.94);
        -webkit-transition: transform 0.5s ease-in-out;
        -moz-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
    }
    &:hover img{
        transform: scale(1);
        -webkit-transition: transform 0.5s ease-in-out;
        -moz-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
      }
}