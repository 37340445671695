.carousel.carousel-slider h1 {
    position: absolute;
    bottom: 25%;
    left: 0;
    right: 0;
    font-size: 65px;
    text-transform: uppercase;
    font-weight: 600;
}

.ost-box{
    background: linear-gradient(259.43deg, #b40f33 1.22%, #da2220 55.14%, #ed5d0f 77.09%, #fcb008 97.13%);;
    border-radius: 8px;
    box-shadow: 3px 3px 3px #0000007d;
    height: 32px;
    width: 137px;
    position: absolute;
    top: 20px;
    right: 140px;
    cursor: pointer;
}

.ost-box p{
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    white-space: nowrap;
}


.video-iframe-section {
    position: relative;
    overflow: hidden;
    padding-top: 39.26%;

    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}




@media only screen and (max-width: 992px) {
    .ost-box{
        right: 40px;
    }
}



@media only screen and (max-width: 576px) {
    .ost-box{
        width: 75px;
        height: 26px;
        p {
            font-size: 12px;
            line-height: 26px;
        }
    }
  }