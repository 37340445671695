@font-face {
    font-family: 'NafeesWeb';
    src: local('NafeesWeb'), url("../../../../public/font/NafeesWeb.woff") format('woff');
}
.synopsis-section{
    background-color: #b50b31;
    padding: 30px 100px;
}


.english-half {
    padding-right: 30px;
    p{
        margin-bottom: 0;
        font-size: 18px;
        line-height: 24px;
    }

    .english-heading {
        color: #f8df6d;
    }



    .synopsis-text {
        margin-bottom: 15px;
        overflow: hidden;
        // max-height: 46px;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;
        margin-bottom: 5px;
    }


    
}

.cast-section-full {
    overflow: hidden;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

p.show-hide-btn-eng{
    color: #f8df6d;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
}

.english-detail-section{
    overflow: hidden;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}


.urdu-half{
    direction: rtl;
    padding-left: 30px;
    h4{
        color: #f8df6d;
        margin-bottom: 0;
        font-family: NafeesWeb;
    }

    p{
        color: #fff;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 0;
        font-family: NafeesWeb;
        &.synopsis-text {
            font-family: NafeesWeb;
            margin-bottom: 15px;
            overflow: hidden;
            // max-height: 46px;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            transition: all 0.5s;
            margin-bottom: 5px;
        }
    }
}

p.show-hide-btn-urdu{
    color: #f8df6d;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
}

.showMobile{
    display: none;
}

.hideMobile{
    display: block;
}

h5.detail-text {
    text-align: center;
    margin-top: 15px;
    cursor: pointer;
    color: #f8df6d;
}


.detail-btn-section{
    display: flex;
    justify-content: center;
    .detail-btn-text{
    margin: 0 5px;
    width: 120px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
    background: linear-gradient(180deg, #ec5a11 0%, #d12117 32.63%, #a80e2d 57.75%, #ec5a11 100%);
    cursor: pointer;
    margin-top: 15px;
    }
}

@media only screen and (max-width: 576px) {
    .urdu-half{
        margin-bottom: 30px;
        p {
            font-size: 18px;
            line-height: 28px;
        }
    }
    .showMobile{
        display: block;
    }
    .hideMobile{
        display: none;
    }

    .english-half p {
        font-size: 14px;
        line-height: 18px;
    }


    .synopsis-section{
        padding: 30px 45px;
    }
}