.loader-section{
    width: 100%;
    height: calc(100vh - 74px);
    background: linear-gradient(180deg, rgba(195,16,46,0.9) 50%, rgba(253,160,32,0.9) 100%);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 200px;
    }
}


@media only screen and (max-width: 787px) {

    .loader-section img{
        width: 100px;
    }

}