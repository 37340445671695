.headerSection-Top{
  position: fixed;
  width: 100%;
  z-index: 50;
}

.HeaderSection {
  background: linear-gradient(0deg, #f8f8f8 0%, #d6dbe4 100%);
  height: 74px;
  display: flex;
  justify-content: space-between;
  padding: 0 115px;

  .menuLogoList {
    display: flex;
  }

  .MenuLogo {
    img {
      height: 66px;
      margin-top: 6px;
      margin-right: 15px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }

  .MenuList {
    list-style: none;
    display: flex;
  }

  .MenuItem {
    padding: 10px;
    margin-top: 13px;
    a {
      color: #7e7e7e;
      text-transform: uppercase;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      text-decoration: none;
      font-size: 16px;
    }

    .active {
      color: #b61234;
    }
  }

  .search-section {
    height: 74px;
    display: flex;
    align-items: center;
  }
}

.mobile-menu {
  display: none;
}

.MobileMenuList {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  //   display: flex;
}

.MobileMenuItem {
  //   padding: 10px;
  //   margin-top: 13px;
  a {
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    height: auto;
    margin: 0;
    padding: 0;
    p {
      margin: 0;
      padding: 10px;
      color: #7e7e7e;
      background: linear-gradient(180deg, #f8f8f8 0%, #d6dbe4 100%);
      text-align: center;
    }
  }

  .active {
    p {
      color: #fff;
      // background: linear-gradient(180deg, #ec5a11 0%, #d12117 32.63%, #a80e2d 57.75%, #ec5a11 100%);
      background: linear-gradient(180deg, rgba(195,16,46,0.8) 50%, rgba(253,160,32,0.8) 100%);
    }
  }
}


.ModalMenu {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(195,16,46,0.8) 50%, rgba(253,160,32,0.8) 100%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OverlayMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: #f00;
  z-index: 600;
}
h1.closeMenuModal {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.social-fixed {
  position: fixed;
  top: calc(50% - 103px);
  left: 40px;
  z-index: 10;
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 5px;
  }

  img {
    width: 36px;
    height: 36px;
  }

}
@media only screen and (max-width: 1200px) {
  .HeaderSection {
    .MenuItem {
      padding: 10px 9px;
      a {
        font-size: 14px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .HeaderSection {
    padding: 0 40px;
    .MenuItem {
      padding: 10px 5px;
      a {
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 787px) {
  .HeaderSection {
    padding: 0 20px;
  }
  .mobile-menu {
    display: flex;
    align-items: center;
    padding: 15px 0 15px 15px;
    cursor: pointer;
  }

  .HeaderSection .MenuList {
    display: none;
  }

  .social-fixed {
    top: calc(50% - 83px);
    left: 5px;
  
    img {
      width: 28px;
      height: 28px;
    }
  
  }
}


@media only screen and (max-width: 486px) {
  .social-fixed {
    top: calc(50% - 73px);  
    img {
      width: 20px;
      height: 20px;
    }
  
  }

}