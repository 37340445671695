.slider-section-top{
    padding: 0 115px;
    // min-height: 544px;
    padding-bottom: 70px;
}


@media only screen and (max-width: 787px) {
    .slider-section-top{
        padding: 0 45px 20px 45px;
        // min-height: 475px;
    }


}

@media only screen and (max-width: 576px) {
    .slider-section-top{
        padding: 0 15px 20px 15px;
        // min-height: 475px;
    }
  }