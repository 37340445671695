@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body, a, p, h1, h2, h3,h4, h5,h6{
  font-family: 'Montserrat', sans-serif;
}

.bg-home{
  background-color: #000;
  overflow-x: auto;
}


.login-section {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.loginitems {
  display: flex;
}

.login-section p{
  color: #f00;
}

.padding-menu{
  margin-top: 74px;

}