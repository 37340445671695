.footer-section{
    padding: 0 115px;
    // min-height: 462px;
}

.footer-top-section {
    display: flex;
    padding: 60px 0;
}

.footer-title {
    display: flex;
    align-items: center;
    width: 400px;
    justify-content: flex-end;
}

.footer-top-section h1 {
    color: #000;
    font-weight: 700;
}

.footer-divider {
    margin: 0 40px;
}
.footer-menu-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 190px;
    margin-left: 20px;
    a {
        text-decoration: none;
    }
}

.footer-menu-1 a {
    align-items: flex-start;
}

.footer-menu-1 p {
    color: #000;
    margin: 0;
    font-weight: 500;
    -webkit-transition: padding 0.5s ease-in-out;
    -moz-transition: padding 0.5s ease-in-out;
    transition: padding 0.5s ease-in-out;
}

.footer-end {
    margin-top: 135px;
    margin-left: 0px;
}

.footer-end p {
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    margin-top: 15px;
}


.social-icons {
    display: flex;
    justify-content: end;
}

.social-icons img {
    margin-left: 10px;
    cursor: pointer;
}

.footer-copyright-mobile {
    height: 30px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(251,148,33,1) 0%, rgba(245,47,9,1) 48%, rgba(188,17,50,1) 100%);
    display: none;
    h6{
        margin: 0;
        font-size: 12px;
    }
}

.footer-section-1{
    display: flex;
    flex: 1;
    justify-content: space-evenly;
}

.footer-section-2{
    display: flex;
}

.empty-menu-space{
    height: 24px;
}

.footer-menu-1 a:hover p {
    padding-left: 5px;
    border-left: 3px solid #feac02;
    -webkit-transition: padding 0.5s ease-in-out;
    -moz-transition: padding 0.5s ease-in-out;
    transition: padding 0.5s ease-in-out;
}

img.sbmedia-logo{
    width: 150px;
}

@media only screen and (max-width: 992px) {
    .footer-end {
        margin-top: 60px;
        margin-left: 0px;
    }
    .footer-top-section{
        padding: 55px 0;
    }
}

@media only screen and (max-width: 787px) {
    .footer-section{
        padding: 0 25px;
    }
  
    .footer-top-section h1{
        font-size: 24px;
    }

    .footer-top-section{
        padding: 20px 0;
    }

    .footer-title{
        width: 245px;
    }

    img.sbmedia-logo {
        width: 90px;
    }

    .footer-divider {
        margin: 0 20px;
    }

    .footer-menu-1{
        margin-left: 10px;
    }

    .footer-menu-1 p{
        font-size: 14px;
    }

    .footer-end {
        margin-top: 30px;
        margin-left: 70px;
    }
    
  }


  @media only screen and (max-width: 576px) {
    .footer-top-section{
        display: block;
        padding: 10px 20px 70px 45px;
    }

    .empty-menu-space{
        height: 14px;
    }
    .footer-section {
        padding: 0 10px 0 25px;
    }
    .footer-title{
        justify-content: center;
    margin-left: 0;
    width: 100%;
    margin-bottom: 15px;
    }
    .footer-section-1{
        justify-content: space-between;
    }

    .footer-menu-1
    {
        width: 125px;
    }
    .footer-menu-1 p {
        margin: 0 3px;
        font-size: 12px;
        text-align: left;
    }

    .footer-divider {
        margin: 0 20px 0 0;
    }

    .footer-end{
        width: 100%;
        margin-top: 0px;
    margin-left: 0px;
    p{
        color: #000;
    }
    }
    .foot-copyright {
        display: none;
    }

    .social-icons{
        margin-bottom: 15px;
        justify-content: end;
    }

    .footer-copyright-mobile {
        display: flex;
    }
    .footer-section-2 {
        display: block;
    }

    .footer-divider img {
        height: 110px;
        width: 3px;
    }

    img.sbmedia-logo {
        width: 80px;
    }

  }