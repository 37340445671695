.contact-us-page {
  padding: 0 115px;
  min-height: 544px;
  padding-bottom: 60px;
}

.contact-form-section {
  margin-top: 60px;

  h6 {
    font-size: 24px;
    margin-top: 20px;
  }

  input,
  textarea {
    width: 100%;
    background: transparent;
    border: 1px solid #fff;
    padding: 8px;
    border-radius: 7px;
    color: #fff;
    outline: none;
  }
}

.contact-form-btn{
    text-align: center;
}

.contact-form-section button {
    text-align: center;
    background-color: #dee0e3;
    border: 1px solid #c8c8c8;
    font-weight: 700;
    padding: 5px 30px;
    border-radius: 10px;
    margin-top: 20px;
}

.address-section {
    margin-top: 70px;
}

.single-address-section {
    display: flex;
}
.address-icon {
    margin-right: 15px;
    font-size: 20px;
    color: #fff;
    min-width: 20px;
}

.address-text p {
    font-size: 18px;
    margin-bottom: 25px;
}

p.error-text {
  color: #fca703;
}

.banner-top-image img {
  width: 100%;
}

.contect-form-submitted {
  min-height: 500px;
  padding-top: 30px;
}

.contect-form-submitted h5, .contect-form-submitted-error h5{
  color: #fca703;
}

.map-embed {
  margin-bottom: 20px;
}

@media only screen and (max-width: 576px) {
  .contact-us-page {
    padding: 0 45px;
  }

  .contact-form-section h6{
    font-size: 18px;
  }

}