
.banner-section{
    position: relative;
    width: 100%;
    // padding-top: 39.259%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.banner-section-inner{
    display: flex;
    align-items: flex-end;
    padding: 5px 20px;
    position: relative;
}



