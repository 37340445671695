.featured-slider-blog{

    .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before{
        border: 0;
        margin: 0;

    }

    .carousel .control-next.control-arrow:before{
        border: 0;
        background: url("../../../public/icons/blog-icon-right.png") no-repeat;
        display: block;
        height: 82px;
        width: 54px;
        content: "";
    }

    .carousel .control-prev.control-arrow:before{
        border: 0;
        background: url("../../../public/icons/blog-icon-left.png") no-repeat;
        display: block;
        height: 82px;
        width: 54px;
        content: "";
    }

    .carousel.carousel-slider .control-arrow:hover{
        background: none;
    }

    .carousel .control-next.control-arrow{
        right: 60px;
    }

    .carousel .control-prev.control-arrow{
        left: 60px;
    }

}
