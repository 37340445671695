.program-section-row{
    margin: 0;
}

.program-section-nav {
    display: flex;
    justify-content: end;
    margin-right: 25px;
    margin-top: 30px;
}

.program-section-nav img {
    margin: 0 15px;
}

.program-nav-btn-left-lock, .program-nav-btn-right-lock {
    font-size: 18px;
    font-weight: 600;
}

.program-nav-btn-right, .program-nav-btn-left {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}


.more-btn{
    cursor: pointer;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 600;
    color: #fff;
    text-align: center;
}