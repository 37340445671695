
.blog-title{
    background: linear-gradient(180deg, rgba(239,242,245,1) 0%, rgba(174,190,223,1) 33%, rgba(174,190,223,1) 57%, rgba(239,242,245,1) 100%);
    text-align: center;
    width: 100%;
    margin: 0;
    font-size: 24px;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px;

}
.blog-content{
    color: #fff;
    padding-bottom: 40px;
}

.blog-top-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0;

    h5 {
        text-transform: uppercase;
        font-weight: 600;
        margin: 0;
    }

    img {
        margin-left: 10px;
        width: 26px;
    }

}

.blog-main-banner{
    img{
        width: 100%;
    }
}