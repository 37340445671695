.video-section-column {
  padding: 50px 44px 0;

  a {
    text-decoration: none;
  }
}

.video-image-title {
  height: 36px;
  background: linear-gradient(
    180deg,
    #ec5a11 0%,
    #d12117 32.63%,
    #a80e2d 57.75%,
    #ec5a11 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  h6 {
    margin: 0 5px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
  }
}

.video-image-single {
  width: 100%;

  .video-image-wraper {
    width: 100%;
    overflow: hidden;
    transform: scale(0.96);
    border: 2px solid #fff;
    -webkit-transition: transform 0.5s ease-in-out;
    -moz-transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video-overlay-image {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
      top: 0;
      bottom: 0;

      img {
        object-fit: none !important;
        opacity: 0;
        -webkit-transition: opacity 0.6s ease-in-out;
        -moz-transition: opacity 0.6s ease-in-out;
        transition: opacity 0.6s ease-in-out;
      }
    }
  }

  &:hover .video-image-wraper {
    transform: scale(1);
    -webkit-transition: transform 0.5s ease-in-out;
    -moz-transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    border: 6px solid #fca703;

    .video-overlay-image {
      img {
        opacity: 1;
        -webkit-transition: opacity 0.6s ease-in-out;
        -moz-transition: opacity 0.6s ease-in-out;
        transition: opacity 0.6s ease-in-out;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .video-image-title h6 {
    font-size: 12px;
  }
  .video-section-content {
    padding: 20px 65px 80px 65px;
  }
  .video-section-column {
    padding: 50px 25px 0;
  }
}

@media only screen and (max-width: 787px) {
  .video-section-column {
    padding: 50px 15px 0;
  }
}
