.slider-section-middle{
    padding: 0 115px;
    // min-height: 2050px;
}


@media only screen and (max-width: 787px) {
    .slider-section-middle{
      padding: 0 20px;
    }
  }
  