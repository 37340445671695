a {
  text-decoration: none;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
}

.blog-single-image {
  position: relative;
  padding-top: 100%;
  overflow: hidden;
}
.blog-single-grid {
  .blog-single-image {
    p {
      margin: 0;
      position: absolute;
      left: 0;
      right: 0;
      font-size: 20px;
    }

    img {
      transform: scale(0.9);
      transition: transform 0.5s ease-in-out;
      border: 2px solid #fff;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  h5 {
    margin: 0;
    margin-bottom: 30px;
    display: block;
    transition: display 0.5s ease-in-out;

  }

  .read-more{
    display: none;
    font-size: 13px;
  }

  &.col-md-6{
    p{
        bottom: 35px;
        transition: bottom 0.5s ease-in-out;
        padding: 0 35px;
    }
  }
  &.col-md-4{
    p{
        bottom: 25px;
        transition: bottom 0.5s ease-in-out;
        padding: 0 20px;
    }
  }

  .overlayBlogImg {
    background-color: #d28d40;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(0.9);
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }




}





@media only screen and (min-width: 787px) {
  .blog-single-grid{
    &:hover .read-more {
      display: block;
  
      }
     &:hover h5 {
      display: none;
      transition: display 0.5s ease-in-out;
  
      }
     &:hover img {
      transform: scale(1);
      transition: transform 0.5s ease-in-out;
      border: 4px solid #fca703;
      }
     &:hover .overlayBlogImg {
      transform: scale(1);
      transition: all 0.5s ease-in-out;
      opacity: 0.4;
      }
     &.col-md-6:hover p {
      bottom: calc(50% - 25px);
      transition: bottom 0.5s ease-in-out;
      }
     &.col-md-4:hover p {
      bottom: calc(50% - 25px);
      transition: bottom 0.5s ease-in-out;
      }
  }

}


@media only screen and (min-width: 1500px) {
  .blog-single-grid.col-md-6{
    p{
        bottom: 60px;
        transition: bottom 0.5s ease-in-out;
    }
  }
  .blog-single-grid.col-md-4{
    p{
        bottom: 40px;
        transition: bottom 0.5s ease-in-out;
    }
  }

}



@media only screen and (max-width: 787px) {
  .blog-single-grid .blog-single-image p{
    font-size: 14px;
  }

  .blog-single-grid.col-md-6 p, .blog-single-grid.col-md-6 p {
    bottom: 25px;
  }
}
