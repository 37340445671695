.not-found{
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 500px;

    h4 {
        color: #000;
        text-align: center;
    }
}

.content-top-division{
    height: 58px;
    background: linear-gradient(180deg, #b3c1dc 0%, #d8dce7 32.63%, #e5e5ed 57.75%, #b3c1dc 100%);
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
        margin: 0 20px;
        color: #000;
        font-size: 18px;
        font-weight: 700;
    }

    .content-top-btns {
        margin: 0 5px;
        width: 120px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        color: #fff;
        font-weight: 700;
        background: linear-gradient(
            180deg,
            #ec5a11 0%,
            #d12117 32.63%,
            #a80e2d 57.75%,
            #ec5a11 100%
          );
          cursor: pointer;
    }
}

.hidesection{
    display: none;
}

.fixEpisodesSection {
    position: fixed;
    top: 74px;
    left: 0;
    width: 100%;
    display: flex;
    z-index: 10;
}



.ost-section-content{

    padding: 10px 80px;

}

@media only screen and (max-width: 787px) {
    .ost-section-content{
        padding: 0;
    }
}
