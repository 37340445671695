.schedule-slide-top a{
    text-decoration: none;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
}

.schedule-page-section .vertical-slide-top {
    p {
        margin: 10px 10px 0 10px;
        color: #fff;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
    }
    span {
        font-weight: 700;
    }
    h5 {
        margin: 0 10px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 24px;
    }

    &:hover h5{
        color: #fca703;
      }

      img {
        transform: scale(.9);
        -webkit-transition: transform 0.5s ease-in-out;
        -moz-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        border: 2px solid #fff;
        border-radius: 10px;
    }
    &:hover img{
        transform: scale(1);
        -webkit-transition: transform 0.5s ease-in-out;
        -moz-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        border: 4px solid #fca703;
      }
}

.schdule-single-imge {
    width: 100%;
    transform: scale(0.9);
    position: relative;
    padding-top: 159.6%;
    overflow: hidden;
}

.schdule-single-imge img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    /* right: 0; */
    /* bottom: 0; */
    width: 100%;
    height: 100%;
}


@media only screen and (max-width: 992px) {
    .schedule-page-section .vertical-slide-top{
        p{
            font-size: 16px;
        }
        h5{
            font-size: 22px;
        }
    }
}


@media only screen and (max-width: 787px) {

    .schedule-slide-top a{
        padding: 0px 5px;
    }

    .schedule-page-section .vertical-slide-top{
        p{
            font-size: 14px;
        }
        h5{
            font-size: 18px;
        }
    }
}

